import { render, staticRenderFns } from "./SupportButton.vue?vue&type=template&id=40be14ae&scoped=true&lang=pug&"
import script from "./SupportButton.vue?vue&type=script&lang=js&"
export * from "./SupportButton.vue?vue&type=script&lang=js&"
import style0 from "./SupportButton.vue?vue&type=style&index=0&id=40be14ae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40be14ae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
